<template>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-left">
          <div class="flex justify-start">
            <BackButton @click="goBack" />
          </div>
        </template>
        <template slot="nav-center">
          <div class="flex justify-center">
            <h1 class="text-3xl whitespace-nowrap">{{ resetTitle }}</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <LoadingCard v-if="isLoading" />
      <ErrorCard v-else-if="errorFetching" message="Dispense details not available" class="error-card"/>
      <DispenseResetCard v-else/>
    </template>
  </PageTemplate>
</template>
<script>

import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import {mapActions} from "vuex";
import BasicNav from "../../components/Helpers/Navs/BasicNav";
import BackButton from "../../components/Helpers/Buttons/BackButton";
import LoadingCard from "../../components/Helpers/Loading/LoadingCard";
import ErrorCard from "../../components/Helpers/Pages/ErrorCard";
import DispenseResetCard from "../../components/Helpers/Cards/DispenseResetCard";
export default {
  name: "DispenserLogResetDetail",
  components: {DispenseResetCard, ErrorCard, LoadingCard, BackButton, BasicNav, PageTemplate},
  data() {
    return {
      isLoading: false,
      errorFetching: false,
      resetTitle: "Cash Dispenser Reset Detail",
    }
  },
  methods: {
    ...mapActions("DispenseLogs", ["getDispenseResetDetails"]),
    goBack() {
      this.$router.push({name: "DispenserLogs"});
    },
    async fetchData() {
      this.isLoading = true;
      const id = this.$route.params.id;
      try {
        await this.getDispenseResetDetails(id);
      } catch (e){
        this.errorFetching = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.fetchData();
  }
}
</script>