<template>
  <div class="bg-white shadow-lg rounded-md px-4 mb-60">
    <div class="grid grid-cols-12 text-left self-center">
      <div :class="`${largeOrangeText} col-span-12 py-4`">Original Values</div>
      <div class="flex col-span-12 divide-x">
        <DenominationValueCell
            v-for="(value, index) in getDispenserDenominations"
            :key="`entered-${index}`"
            :label="value.label"
            :value="getOldValuesByDenomination(value.denomination)"
            :class="`flex-1 ${value.denomination}-original-box`"
        />
      </div>
    </div>
    <div class="grid grid-cols-12 text-left self-center">
      <div :class="`${largeOrangeText} col-span-12 py-4`">New Values</div>
      <div class="flex col-span-12 divide-x">
        <DenominationValueCell
            v-for="(value, index) in getDispenserDenominations"
            :key="`entered-${index}`"
            :label="value.label"
            :value="getNewValuesByDenomination(value.denomination)"
            :class="`flex-1 ${value.denomination}-new-box`"
        />
      </div>
    </div>
    <div class="grid-cols-8 text-left border-b py-4">
      <span :class="`${largeOrangeText}`">Error Code:</span>
      <span class="text-md self-center mt-0.5 ml-5">{{ record.errorCode || "None" }}</span>
    </div>
    <div class="grid grid-cols-8 text-left py-4">
      <span :class="`${largeOrangeText} whitespace-nowrap col-span-1 self-center`">Error Message:</span>
      <span class="col-span-7 self-center text-md self-center mt-0.5 ml-5">{{ record.statusCode || "None" }}</span>
    </div>
  </div>
</template>

<script>
import TransactionLogDetail from "../../../mixin/TransactionLogDetail";
import DenominationValueCell from "../DenominationValueCell";
import {mapState} from "vuex";
import _ from "lodash";
import currency from "currency.js";

export default {
  name: "DispenseResetCard",
  mixins: [TransactionLogDetail],
  components: { DenominationValueCell },
  computed: {
    ...mapState("DispenseLogs", ["resetDetails"]),
    record() {
      return this.resetDetails;
    },
  },
  methods: {
    getOldValuesByDenomination(denomination) {
      const total = _.find(this.record["originalValues"], {denomination: denomination})?.total;
      return total ? currency(total).format() : currency(0).format();
    },
    getNewValuesByDenomination(denomination) {
      const total = _.find(this.record["newValues"], {denomination: denomination})?.total;
      return total ? currency(total).format() : currency(0).format();
    },
  },
}
</script>